import React, {useRef, useState} from 'react';

import * as styles from './LeiaMais.module.scss';
import PropTypes from "prop-types";

const LeiaMais = ({children}) => {
    const [readMore, setReadMore] = useState(false);
    const extraContent = <div className={styles.extraContent}>
        <React.Fragment>
            {children}
        </React.Fragment>
    </div>
    const linkName = readMore ? 'Leia Menos -' : '+ Continue Lendo ';

    const myRef = useRef(null);
    const executeScroll = () => {
        setTimeout(function() {
            const yOffset = -100;
            const element = myRef.current;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }, 500);
    }

    return (
        <div className={styles.container}>
            <div>
                <a ref={myRef} className={styles.readMoreLink} onClick={() => {
                    setReadMore(!readMore);
                    executeScroll();
                }}>{linkName}</a>
            </div>
            {readMore && extraContent}
        </div>
    );
}

LeiaMais.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LeiaMais;
