import React from 'react';
import PropTypes from 'prop-types';

import ExtLink from './ExtLink';

import Facebook from '../svg/facebook.svg';
import Twitter from '../svg/twitter.svg';
import WhatsApp from '../svg/whatsapp.svg';
import Instagram from '../svg/instagram.svg';
import YouTube from '../svg/youtube.svg';
import Email from '../svg/email.svg';


const SocialNetworks = ({facebook, twitter, whatsapp, instagram, youtube, email}) => {
    return (
        <React.Fragment>
            { facebook &&
                <ExtLink to={`https://facebook.com/${facebook}`}><Facebook/></ExtLink>
            }
            { instagram &&
                <ExtLink to={`https://instagram.com/${instagram}`}><Instagram/></ExtLink>
            }
            { twitter &&
                <ExtLink to={`https://twitter.com/${twitter}`}><Twitter/></ExtLink>
            }
            { youtube &&
                <ExtLink to={`https://youtube.com/${youtube}`}><YouTube/></ExtLink>
            }
            { email &&
                <ExtLink to={`mailto://${email}`}><Email/></ExtLink>
            }
            { whatsapp &&
                <ExtLink to={`https://wa.me/${whatsapp}`}><WhatsApp/></ExtLink>
            }
        </React.Fragment>
    );
};

SocialNetworks.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
};

export default SocialNetworks;

