import React, { useState } from "react";
import PropTypes from "prop-types";

import * as styles from "./Linguagens.module.scss";
import LinguagemItem from "./LinguagemItem";
import LinguagensMenu from "./LinguagensMenu";

const Linguagens = ({ title, items }) => {
  const initialStates = items.map((item, index) => {
    return {
      id: index,
      active: false,
      content: item,
    };
  });
  initialStates[0].active = true;
  const [statedItems, toggleStates] = useState(initialStates);

  const toggleState = (id) => {
    const newStates = statedItems.map((item) =>
      item.id === id ? { ...item, active: true } : { ...item, active: false }
    );

    toggleStates(newStates);
  };

  return (
    <div id="manifesto" className={styles.container}>
      <div className={styles.header}>
          <h1 className={styles.titleManifesto}>
            <span className="emphasis">Manifesto Internacional</span>
          </h1>
          <ul className={styles.buttons}>
            {statedItems.map((item) => (
              <LinguagensMenu
                item={item.content.name}
                isActive={item.active}
                toggle={() => toggleState(item.id)}
              />
            ))}
          </ul>
      </div>
      <section className={styles.content}>
        {statedItems.map((item) => {
          return (
            <LinguagemItem
              item={item.content.name}
              content={item.content.text}
              isActive={item.active}
            />
          );
        })}
      </section>
    </div>
  );
};

Linguagens.propTypes = {
  title: PropTypes.object,
  items: PropTypes.array,
};

export default Linguagens;
