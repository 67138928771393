import React from "react";
import PropTypes from "prop-types";

import * as styles from "./Manifesto.module.scss";
import LeiaMais from "./LeiaMais";
import Linguagens from "./Linguagens";

const Manifesto = () => (
  <div className={styles.container}>
    <a className={styles.anchor} id="manifesto" />
    <div className={styles.wrap}>
      <div className={styles.manifesto}>
        <Linguagens
          title={"Manifesto Internacional"}
          items={[
            {
              name: "PT",
              text: [
                <p className={styles.primeiroparagrafo}>
                  <i>
                    “Devemos trabalhar muito para liquidar na nossa cabeça a
                    cultura colonial, camaradas. E queiramos ou não, na cidade
                    ou no mato, o colonialismo meteu-nos muitas coisas na
                    cabeça. E o nosso trabalho deve ser tirar aquilo que não
                    presta e deixar aquilo que é bom. [...] A nossa resistência
                    cultural consiste no seguinte: enquanto liquidamos a cultura
                    colonial e os aspectos negativos da nossa própria cultura no
                    nosso espírito, no nosso meio, temos que criar uma cultura
                    nova, baseada nas nossas tradições também, mas respeitando
                    tudo quanto o mundo tem hoje de conquista para servir o
                    homem.”
                  </i>
                  <strong>
                    {" "}
                    — Amilcar Cabral em “Análise de alguns tipos de resistência”
                    (1979)
                  </strong>
                </p>,

                <p>
                  <strong>O Racismo não é natural.</strong>. Desde a
                  hierarquização das raças até o eugenismo e o nazismo, o
                  racismo é uma invenção da modernidade criada para justificar a
                  opressão e exploração de determinados povos e culturas, em
                  especial os que hoje entendemos como africanos, ameríndios e
                  asiáticos. Portanto, não devemos naturalizar a raça, como se
                  fosse algo que sempre existiu e sempre fará parte do nosso
                  cotidiano. As sociedades mudam ao passar dos séculos, sendo
                  regidas por diversas ordens. São processos sociais, políticos
                  e culturais influenciados pela luta de classes. Muitas vezes
                  consciente, os donos do poder fazem a manutenção do racismo
                  para manter seus privilégios.
                </p>,

                <LeiaMais>
                  <p>
                    <strong>A cidade é do povo.</strong>
                    Do norte ao sul, seja do Brasil ou do globo, nas áreas
                    urbanas, rurais ou de floresta, o capitalismo neoliberal nos
                    ensina que nossos territórios simplesmente “são”, não sendo
                    passíveis de mudanças. Quem manda nas cidades são as
                    inquestionáveis coordenadorias, associações, prefeituras e
                    governos, e não o povo. De fato, são poucos os países em que
                    a população é ouvida na hora de pensar políticas públicas.
                    Mas esquecemos muitas vezes que cada território tem
                    história. Mas quem concretamente vive nos bairros,
                    subúrbios, favelas e quilombos influencia também na formação
                    desses lugares. Tem histórias próprias que devem ser
                    contadas e priorizadas. E tem direitos sobre o próprio
                    território e sua autodeterminação que devem ser garantidos.
                    É dever dos governadores, prefeitos, deputados e vereadores
                    atuarem para garantir que os cidadãos e seus movimentos
                    organizados tenham voz nas decisões da cidade.
                  </p>

                  <p>
                    <strong>
                      A história é política concreta e não pode ser apagada.
                    </strong>
                    Falar de memória não é falar de algo abstrato. A história
                    contada sobre os nossos territórios reflete as relações de
                    poder. Reflete a forma como ela foi e ainda é construída,
                    dividida, distribuída e ocupada. No caso de países como
                    Brasil, essa história é atravessada por quase quatro séculos
                    de colonização e opressão dos povos nativos e de africanos
                    escravizados. Dizer que o Brasil é solo preto e indígena não
                    é excluir bandeirantes, colonizadores, racistas e eugenistas
                    da história — isso é impossível. É fundamental contar sobre
                    essas figuras e movimentos, pois eles são parte da história
                    e justificam como chegamos até aqui. Ao contrário, é
                    fundamental repensar as estátuas, nomes de ruas e praças
                    concedidas como homenagens aos feitos desses homens em
                    detrimento do apagamento da presença e contribuição das
                    populações negras e indígenas — maioria do povo brasileiro —
                    para a nossa história e cultura.
                  </p>

                  <p>
                    <strong>Existem várias experiências pelo mundo.</strong>{" "}
                    Recontar a história para não apagar movimentos e figuras
                    históricas de nosso povo é um exercício de democracia. Na
                    Alemanha, houve um processo radical de retirada de símbolos
                    da ideologia nazista da vida pública do país, substituindo
                    todas as referências por intelectuais e artistas alemães; no
                    Chile, foi lançado o programa Ninguna Calle Llevará tu
                    Nombre, que extinguiu as homenagens vinculadas à ditadura do
                    Pinochet; processos semelhantes foram adotados em relação ao
                    regime fascista na Itália e para as referências ao
                    Apartheid, o regime de regregação racial que ocorreu na
                    África do Sul; recentemente, o México realocou uma estátua
                    de Colombo, substituindo-a por uma mulher indígena; e nos
                    EUA, foi removida uma das maiores estaduas confederadas,
                    movimento que defendia a manutenção da escravidão. Ainda que
                    centenas de juristas, historiadores e ativistas venham
                    alertando há décadas essa necessidade histórica, no Brasil,
                    não houveram medidas de justiça de transição ou algo
                    semelhante, nem em relação à colonização e nem às ditaduras
                    que este país sofreu. Precisamos mudar essa realidade.
                  </p>
                </LeiaMais>,
              ],
            },

            {
              name: "FR",
              text: [
                <p className={styles.primeiroparagrafo}>
                   “Devemos trabalhar muito para liquidar na nossa cabeça a
                    cultura colonial, camaradas. E queiramos ou não, na cidade
                    ou no mato, o colonialismo meteu-nos muitas coisas na
                    cabeça. E o nosso trabalho deve ser tirar aquilo que não
                    presta e deixar aquilo que é bom. [...] A nossa resistência
                    cultural consiste no seguinte: enquanto liquidamos a cultura
                    colonial e os aspectos negativos da nossa própria cultura no
                    nosso espírito, no nosso meio, temos que criar uma cultura
                    nova, baseada nas nossas tradições também, mas respeitando
                    tudo quanto o mundo tem hoje de conquista para servir o
                    homem.”
                </p>,

                <p>
                  <strong>O Racismo não é natural.</strong>. Desde a
                  hierarquização das raças até o eugenismo e o nazismo, o
                  racismo é uma invenção da modernidade criada para justificar a
                  opressão e exploração de determinados povos e culturas, em
                  especial os que hoje entendemos como africanos, ameríndios e
                  asiáticos. Portanto, não devemos naturalizar a raça, como se
                  fosse algo que sempre existiu e sempre fará parte do nosso
                  cotidiano. As sociedades mudam ao passar dos séculos, sendo
                  regidas por diversas ordens. São processos sociais, políticos
                  e culturais influenciados pela luta de classes. Muitas vezes
                  consciente, os donos do poder fazem a manutenção do racismo
                  para manter seus privilégios.
                </p>,

                <LeiaMais>
                  <p>
                    <strong>A cidade é do povo.</strong>
                    Do norte ao sul, seja do Brasil ou do globo, nas áreas
                    urbanas, rurais ou de floresta, o capitalismo neoliberal nos
                    ensina que nossos territórios simplesmente “são”, não sendo
                    passíveis de mudanças. Quem manda nas cidades são as
                    inquestionáveis coordenadorias, associações, prefeituras e
                    governos, e não o povo. De fato, são poucos os países em que
                    a população é ouvida na hora de pensar políticas públicas.
                    Mas esquecemos muitas vezes que cada território tem
                    história. Mas quem concretamente vive nos bairros,
                    subúrbios, favelas e quilombos influencia também na formação
                    desses lugares. Tem histórias próprias que devem ser
                    contadas e priorizadas. E tem direitos sobre o próprio
                    território e sua autodeterminação que devem ser garantidos.
                    É dever dos governadores, prefeitos, deputados e vereadores
                    atuarem para garantir que os cidadãos e seus movimentos
                    organizados tenham voz nas decisões da cidade.
                  </p>

                  <p>
                    <strong>
                      A história é política concreta e não pode ser apagada.
                    </strong>
                    Falar de memória não é falar de algo abstrato. A história
                    contada sobre os nossos territórios reflete as relações de
                    poder. Reflete a forma como ela foi e ainda é construída,
                    dividida, distribuída e ocupada. No caso de países como
                    Brasil, essa história é atravessada por quase quatro séculos
                    de colonização e opressão dos povos nativos e de africanos
                    escravizados. Dizer que o Brasil é solo preto e indígena não
                    é excluir bandeirantes, colonizadores, racistas e eugenistas
                    da história — isso é impossível. É fundamental contar sobre
                    essas figuras e movimentos, pois eles são parte da história
                    e justificam como chegamos até aqui. Ao contrário, é
                    fundamental repensar as estátuas, nomes de ruas e praças
                    concedidas como homenagens aos feitos desses homens em
                    detrimento do apagamento da presença e contribuição das
                    populações negras e indígenas — maioria do povo brasileiro —
                    para a nossa história e cultura.
                  </p>

                  <p>
                    <strong>Existem várias experiências pelo mundo.</strong>{" "}
                    Recontar a história para não apagar movimentos e figuras
                    históricas de nosso povo é um exercício de democracia. Na
                    Alemanha, houve um processo radical de retirada de símbolos
                    da ideologia nazista da vida pública do país, substituindo
                    todas as referências por intelectuais e artistas alemães; no
                    Chile, foi lançado o programa Ninguna Calle Llevará tu
                    Nombre, que extinguiu as homenagens vinculadas à ditadura do
                    Pinochet; processos semelhantes foram adotados em relação ao
                    regime fascista na Itália e para as referências ao
                    Apartheid, o regime de regregação racial que ocorreu na
                    África do Sul; recentemente, o México realocou uma estátua
                    de Colombo, substituindo-a por uma mulher indígena; e nos
                    EUA, foi removida uma das maiores estaduas confederadas,
                    movimento que defendia a manutenção da escravidão. Ainda que
                    centenas de juristas, historiadores e ativistas venham
                    alertando há décadas essa necessidade histórica, no Brasil,
                    não houveram medidas de justiça de transição ou algo
                    semelhante, nem em relação à colonização e nem às ditaduras
                    que este país sofreu. Precisamos mudar essa realidade.
                  </p>
                </LeiaMais>,
              ],
            },

            {
              name: "EN",
              text: [
                <p className={styles.primeiroparagrafo}>
                  <i>
                    “Devemos trabalhar muito para liquidar na nossa cabeça a
                    cultura colonial, camaradas. E queiramos ou não, na cidade
                    ou no mato, o colonialismo meteu-nos muitas coisas na
                    cabeça. E o nosso trabalho deve ser tirar aquilo que não
                    presta e deixar aquilo que é bom. [...] A nossa resistência
                    cultural consiste no seguinte: enquanto liquidamos a cultura
                    colonial e os aspectos negativos da nossa própria cultura no
                    nosso espírito, no nosso meio, temos que criar uma cultura
                    nova, baseada nas nossas tradições também, mas respeitando
                    tudo quanto o mundo tem hoje de conquista para servir o
                    homem.”
                  </i>
                  <strong>
                    {" "}
                    — Amilcar Cabral em “Análise de alguns tipos de resistência”
                    (1979)
                  </strong>
                </p>,

                <p>
                  <strong>O Racismo não é natural.</strong>. Desde a
                  hierarquização das raças até o eugenismo e o nazismo, o
                  racismo é uma invenção da modernidade criada para justificar a
                  opressão e exploração de determinados povos e culturas, em
                  especial os que hoje entendemos como africanos, ameríndios e
                  asiáticos. Portanto, não devemos naturalizar a raça, como se
                  fosse algo que sempre existiu e sempre fará parte do nosso
                  cotidiano. As sociedades mudam ao passar dos séculos, sendo
                  regidas por diversas ordens. São processos sociais, políticos
                  e culturais influenciados pela luta de classes. Muitas vezes
                  consciente, os donos do poder fazem a manutenção do racismo
                  para manter seus privilégios.
                </p>,

                <LeiaMais>
                  <p>
                    <strong>A cidade é do povo.</strong>
                    Do norte ao sul, seja do Brasil ou do globo, nas áreas
                    urbanas, rurais ou de floresta, o capitalismo neoliberal nos
                    ensina que nossos territórios simplesmente “são”, não sendo
                    passíveis de mudanças. Quem manda nas cidades são as
                    inquestionáveis coordenadorias, associações, prefeituras e
                    governos, e não o povo. De fato, são poucos os países em que
                    a população é ouvida na hora de pensar políticas públicas.
                    Mas esquecemos muitas vezes que cada território tem
                    história. Mas quem concretamente vive nos bairros,
                    subúrbios, favelas e quilombos influencia também na formação
                    desses lugares. Tem histórias próprias que devem ser
                    contadas e priorizadas. E tem direitos sobre o próprio
                    território e sua autodeterminação que devem ser garantidos.
                    É dever dos governadores, prefeitos, deputados e vereadores
                    atuarem para garantir que os cidadãos e seus movimentos
                    organizados tenham voz nas decisões da cidade.
                  </p>

                  <p>
                    <strong>
                      A história é política concreta e não pode ser apagada.
                    </strong>
                    Falar de memória não é falar de algo abstrato. A história
                    contada sobre os nossos territórios reflete as relações de
                    poder. Reflete a forma como ela foi e ainda é construída,
                    dividida, distribuída e ocupada. No caso de países como
                    Brasil, essa história é atravessada por quase quatro séculos
                    de colonização e opressão dos povos nativos e de africanos
                    escravizados. Dizer que o Brasil é solo preto e indígena não
                    é excluir bandeirantes, colonizadores, racistas e eugenistas
                    da história — isso é impossível. É fundamental contar sobre
                    essas figuras e movimentos, pois eles são parte da história
                    e justificam como chegamos até aqui. Ao contrário, é
                    fundamental repensar as estátuas, nomes de ruas e praças
                    concedidas como homenagens aos feitos desses homens em
                    detrimento do apagamento da presença e contribuição das
                    populações negras e indígenas — maioria do povo brasileiro —
                    para a nossa história e cultura.
                  </p>

                  <p>
                    <strong>Existem várias experiências pelo mundo.</strong>{" "}
                    Recontar a história para não apagar movimentos e figuras
                    históricas de nosso povo é um exercício de democracia. Na
                    Alemanha, houve um processo radical de retirada de símbolos
                    da ideologia nazista da vida pública do país, substituindo
                    todas as referências por intelectuais e artistas alemães; no
                    Chile, foi lançado o programa Ninguna Calle Llevará tu
                    Nombre, que extinguiu as homenagens vinculadas à ditadura do
                    Pinochet; processos semelhantes foram adotados em relação ao
                    regime fascista na Itália e para as referências ao
                    Apartheid, o regime de regregação racial que ocorreu na
                    África do Sul; recentemente, o México realocou uma estátua
                    de Colombo, substituindo-a por uma mulher indígena; e nos
                    EUA, foi removida uma das maiores estaduas confederadas,
                    movimento que defendia a manutenção da escravidão. Ainda que
                    centenas de juristas, historiadores e ativistas venham
                    alertando há décadas essa necessidade histórica, no Brasil,
                    não houveram medidas de justiça de transição ou algo
                    semelhante, nem em relação à colonização e nem às ditaduras
                    que este país sofreu. Precisamos mudar essa realidade.
                  </p>
                </LeiaMais>,
              ],
            },

            {
              name: "ES",
              text: [
                <p className={styles.primeiroparagrafo}>
                  <i>
                    “Devemos trabalhar muito para liquidar na nossa cabeça a
                    cultura colonial, camaradas. E queiramos ou não, na cidade
                    ou no mato, o colonialismo meteu-nos muitas coisas na
                    cabeça. E o nosso trabalho deve ser tirar aquilo que não
                    presta e deixar aquilo que é bom. [...] A nossa resistência
                    cultural consiste no seguinte: enquanto liquidamos a cultura
                    colonial e os aspectos negativos da nossa própria cultura no
                    nosso espírito, no nosso meio, temos que criar uma cultura
                    nova, baseada nas nossas tradições também, mas respeitando
                    tudo quanto o mundo tem hoje de conquista para servir o
                    homem.”
                  </i>
                  <strong>
                    {" "}
                    — Amilcar Cabral em “Análise de alguns tipos de resistência”
                    (1979)
                  </strong>
                </p>,

                <p>
                  <strong>O Racismo não é natural.</strong>. Desde a
                  hierarquização das raças até o eugenismo e o nazismo, o
                  racismo é uma invenção da modernidade criada para justificar a
                  opressão e exploração de determinados povos e culturas, em
                  especial os que hoje entendemos como africanos, ameríndios e
                  asiáticos. Portanto, não devemos naturalizar a raça, como se
                  fosse algo que sempre existiu e sempre fará parte do nosso
                  cotidiano. As sociedades mudam ao passar dos séculos, sendo
                  regidas por diversas ordens. São processos sociais, políticos
                  e culturais influenciados pela luta de classes. Muitas vezes
                  consciente, os donos do poder fazem a manutenção do racismo
                  para manter seus privilégios.
                </p>,

                <LeiaMais>
                  <p>
                    <strong>A cidade é do povo.</strong>
                    Do norte ao sul, seja do Brasil ou do globo, nas áreas
                    urbanas, rurais ou de floresta, o capitalismo neoliberal nos
                    ensina que nossos territórios simplesmente “são”, não sendo
                    passíveis de mudanças. Quem manda nas cidades são as
                    inquestionáveis coordenadorias, associações, prefeituras e
                    governos, e não o povo. De fato, são poucos os países em que
                    a população é ouvida na hora de pensar políticas públicas.
                    Mas esquecemos muitas vezes que cada território tem
                    história. Mas quem concretamente vive nos bairros,
                    subúrbios, favelas e quilombos influencia também na formação
                    desses lugares. Tem histórias próprias que devem ser
                    contadas e priorizadas. E tem direitos sobre o próprio
                    território e sua autodeterminação que devem ser garantidos.
                    É dever dos governadores, prefeitos, deputados e vereadores
                    atuarem para garantir que os cidadãos e seus movimentos
                    organizados tenham voz nas decisões da cidade.
                  </p>

                  <p>
                    <strong>
                      A história é política concreta e não pode ser apagada.
                    </strong>
                    Falar de memória não é falar de algo abstrato. A história
                    contada sobre os nossos territórios reflete as relações de
                    poder. Reflete a forma como ela foi e ainda é construída,
                    dividida, distribuída e ocupada. No caso de países como
                    Brasil, essa história é atravessada por quase quatro séculos
                    de colonização e opressão dos povos nativos e de africanos
                    escravizados. Dizer que o Brasil é solo preto e indígena não
                    é excluir bandeirantes, colonizadores, racistas e eugenistas
                    da história — isso é impossível. É fundamental contar sobre
                    essas figuras e movimentos, pois eles são parte da história
                    e justificam como chegamos até aqui. Ao contrário, é
                    fundamental repensar as estátuas, nomes de ruas e praças
                    concedidas como homenagens aos feitos desses homens em
                    detrimento do apagamento da presença e contribuição das
                    populações negras e indígenas — maioria do povo brasileiro —
                    para a nossa história e cultura.
                  </p>

                  <p>
                    <strong>Existem várias experiências pelo mundo.</strong>{" "}
                    Recontar a história para não apagar movimentos e figuras
                    históricas de nosso povo é um exercício de democracia. Na
                    Alemanha, houve um processo radical de retirada de símbolos
                    da ideologia nazista da vida pública do país, substituindo
                    todas as referências por intelectuais e artistas alemães; no
                    Chile, foi lançado o programa Ninguna Calle Llevará tu
                    Nombre, que extinguiu as homenagens vinculadas à ditadura do
                    Pinochet; processos semelhantes foram adotados em relação ao
                    regime fascista na Itália e para as referências ao
                    Apartheid, o regime de regregação racial que ocorreu na
                    África do Sul; recentemente, o México realocou uma estátua
                    de Colombo, substituindo-a por uma mulher indígena; e nos
                    EUA, foi removida uma das maiores estaduas confederadas,
                    movimento que defendia a manutenção da escravidão. Ainda que
                    centenas de juristas, historiadores e ativistas venham
                    alertando há décadas essa necessidade histórica, no Brasil,
                    não houveram medidas de justiça de transição ou algo
                    semelhante, nem em relação à colonização e nem às ditaduras
                    que este país sofreu. Precisamos mudar essa realidade.
                  </p>
                </LeiaMais>,
              ],
            },
          ]}
        ></Linguagens>
      </div>
    </div>
  </div>
);

Manifesto.propTypes = {
  background: PropTypes.object,
};

export default Manifesto;
