import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Linguagens.module.scss';

const LinguagensMenu = ({item, isActive, toggle}) => (
  <li className={`${styles.button} ${isActive ? styles.active : ''}`} onClick={() => toggle()}><a>{item}</a></li>
);

LinguagensMenu.propTypes = {
  item: PropTypes.string,
  isActive: PropTypes.bool,
  toggle: PropTypes.func
};

export default LinguagensMenu;