import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import "@evag/ec-react/dist/main.css";
import CampaignForm from "../components/CampaignForm";
import Hero from "../components/Hero";
import Menu from "../components/Menu";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Manifesto from "../components/Manifesto";
import Apoiadores from "../components/Apoiadores";
import SocialNetworks from "../components/SocialNetworks";
import { GatsbyImage } from "gatsby-plugin-image";

export default function render({ data }) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO
        title={data.site.meta.title}
        ogimage={data.ogImage.childImageSharp.gatsbyImageData}
      />

      <Menu
        logo={data.logo.childImageSharp.gatsbyImageData}
        items={[
          { to: "/#manifesto", name: "Internacional" },
          { to: "http://sp.solopretoeindigena.com.br", name: "São Paulo" },
          { to: "/contribuicoes", name: "Contribuições" },
          { to: "/#iniciativas", name: "Iniciativas" },
        ]}
        instagram={{
          logo: data.instagram.childImageSharp.gatsbyImageData,
          user: "solopretoeindigena",
        }}
        site={data.site.meta}
      />

      <Hero
        left={data.hero.childImageSharp.gatsbyImageData}
        channel={data.site.meta.channel}
      />

      <Manifesto share={data.site.meta.description} />

      <Apoiadores
        data={[
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
          {
            name: "Luana Alves",
            organizacao: "Líder da bancada do PSOL-SP",
          },
        ]}
      />

      <footer className="footer">
        <div className="wrap">
          <div className="description">
            <div className="info">
              <h1>Uma iniciativa do mandato da vereadora Luana Alves</h1>
            </div>
            <div className="social-networks">
              <SocialNetworks
                twitter="luanapsol"
                facebook="luanapsol"
                instagram="luanapsol"
                youtube="channel/UClZyv3izFgsogtxHI1Z9Cxw"
                email="contato@luanapsol.com.br.com"
              />
            </div>
          </div>
          <div className="logos">
            <GatsbyImage
              className="logo"
              alt="Punho - SP é Solo Preto!"
              objectFit="contain"
              style={{ position: false }}
              image={data.punho.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </footer>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }

    logo: file(relativePath: { eq: "pretoindigena.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }

    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }

    ogImage: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }

    formacaoLogos: file(relativePath: { eq: "formacao-logos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }

    punho: file(relativePath: { eq: "punho-footer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }

  }
`;
