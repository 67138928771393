import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Linguagens.module.scss';

const LinguagemItem = ({content, isActive}) => (
  <div className={`${styles.contentItem} ${isActive ? styles.active : ''}`}>
    <article>
      {content}
    </article>
  </div>);

LinguagemItem.propTypes = {
  content: PropTypes.string,
  isActive: PropTypes.bool
};

export default LinguagemItem;
